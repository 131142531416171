<template>
  <v-container>
    <v-row
      class="align-center"
      align="center"
      justify="center"
    >
      <v-col cols="8">
        <div style="text-align: center;">
          <v-avatar
            class="mt-5 mb-5"
            color="primary"
            size="96"
          >
            <span class="white--text headline">{{ initials }}</span>
          </v-avatar>
          <h2>{{ $t('welcome') }}, {{ user.displayName }}</h2>
          <p>{{ $t('manageInfoPrivacySecurity') }}</p>
        </div>
      </v-col>
    </v-row>
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        md="4"
      >
        <v-card outlined>
          <v-card-title>{{ $t('security') }}</v-card-title>
          <v-card-subtitle>{{ $t('changeAccountEmailPassword') }}</v-card-subtitle>
          <v-card-actions>
            <v-btn
              block
              color="primary"
              link
              to="/account/security"
            >
              {{ $t('manage') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-card outlined>
          <v-card-title>{{ $t('organizations') }}</v-card-title>
          <v-card-subtitle>{{ $t('reviewOrganizationsAccess') }}</v-card-subtitle>
          <v-card-actions>
            <v-btn
              block
              color="primary"
              to="account/organizations"
            >
              {{ $t('review') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
    name: 'Account',
    computed: {
        initials() {
            return this.user.displayName.getInitials()
        },
        user() {
            return this.$store.state.user
        }
    }
}
</script>